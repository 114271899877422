import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { Button } from '@planity/ui';
import classNames from 'classnames';
import styles from './hidden_map.module.scss';
import credentials from '@planity/credentials';

const MAP_ZOOM_L =
	`${credentials.CDN_IMAGES_PLANITY}/next/L.webp`;

/**
 *
 * @param {Function} props.setIsMapShowed - Callback function to display the map.
 * @param {number} prop.height
 *
 * @returns {JSX.Element}
 */
export const HiddenMap = ({ height, setIsMapShowed }) => {
	const { t } = useTranslation();
	useStyles(styles);
	return (
		<div className={classNames(styles.mapBackground)} style={{ height }}>
			<Button
				iconLeft={'MapIcon'}
				label={t(`search.displayMap`)}
				className={styles.button}
				type='primary'
				onClick={setIsMapShowed}
			/>
			<img src={MAP_ZOOM_L} className={styles.image} />
		</div>
	);
};
