export default {
	FIREBASE_API_KEY: 'AIzaSyDopTlObOWruWzt_1bV1hvXqDzK7wtoU00',
	FIREBASE_AUTH_DOMAIN: 'planity-lab.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://planity-lab.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_URL:
		'https://planity-lab-availabilities.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_1_URL:
		'https://planity-lab-availabilities-shard-1.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_2_URL:
		'https://planity-lab-availabilities-shard-2.europe-west1.firebasedatabase.app',
	FIREBASE_PUBLIC_SHARD_URL: 'https://planity-lab-public.firebaseio.com',
	FIREBASE_STORAGE_BUCKET: 'planity-lab.appspot.com',
	AWS_API_ENDPOINT: 'https://product-api.lab.planity.app',
	ALGOLIA_APP_ID: 'QLUMJ5JSOZ',
	ALGOLIA_API_KEY: '73f4900caa6c99cb07d43a6ba4d902d7',
	ALGOLIA_PRODUCTION_APP_ID: 'DAY79MUBW3',
	ALGOLIA_PRODUCTION_API_KEY: '8ec84cdda274cec79b9ad155973bc864',
	ALGOLIA_PLACES_APP_ID: 'pl574WJIL1Q2',
	ALGOLIA_PLACES_API_KEY: '719fde67c8532799b0f99a4d2b454daa',
	GOOGLE_API_BROWSER_KEY:
		process.env.NODE_ENV === 'development'
			? 'AIzaSyBvhQQf1t6BHskINw9SyKdI8AE0pW4KTFc'
			: 'AIzaSyDg_KmgH0BMgedJ7wYRSITlHuemZ4sutp4',
	HOST: 'planitylab.com',
	CLOUD_FUNCTIONS_DOMAIN: 'https://us-central1-planity-lab.cloudfunctions.net',
	MAPBOX_API_KEY:
		'pk.eyJ1IjoicGxhbml0eSIsImEiOiI4QU5kRy1jIn0.suHIBfZHkiiPnuVFhQSRMg',
	USER_APP_LINK: 'https://planitylab.onelink.me/ANzA/qsof3fpy',
	PARANOID_AVAILABILITIES: ['saco'],
	STRIPE_API_KEY:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_FR:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_BE:
		'pk_test_51Lfk9VFCwhxbUJ7mSdwaFCrP38BgelUQPvOjK7zbpZGHIvdyVRkfpvreRVHeoees2df6titaLQEjUAtxQfscNPkc00clFCnAsf',
	STRIPE_API_KEY_DE:
		'pk_test_51L15sZE7ePEuIRmRVnUPGutdLBj8udBvUsP86xlxMvdklBgHOSP2E3b57cz9rAP1wtdYCh4vjFazd49CiqUNwxgU0003HcpCuk',
	DISPLAY_REVIEWS: true,
	HIDE_ESHOP: false,
	HIDE_GIFT_VOUCHER_BUTTON: false,
	ENABLE_FACEBOOK_LOGIN: false,
	ENABLE_GOOGLE_LOGIN: false,
	ENABLE_GITHUB_LOGIN: false,
	// Just in case we enable and then disable a provider, we can filter "not used anymore" providers when getting the user's prefered providers
	AVAILABLE_PROVIDERS: ['password'],
	ENABLE_USER_PAYS_FEES: true,
	PLANITY_FEE: 1.58,
	PLANITY_FLAT: 18,
	STRIPE_FEE: 0,
	STRIPE_FLAT: 0,
	ENABLE_CLICK_AND_COLLECT: 'true',
	ENABLE_CLICK_AND_SHOP: true,
	WOOTRIC_ACCOUNT_TOKEN: 'NPS-593dabed',
	WOOTRIC_NO_SURVEYED_COOKIES: false,
	WOOTRIC_SURVEY_IMMEDIATELY: false,
	ENABLE_NPS: false,
	ENABLE_ENRICHMENT: true,
	CATEGORIES_INDEX: 'categories',
	TAGS_INDEX: 'tags',
	PLACES_INDEX: 'places',
	BUSINESSES_INDEX: 'businesses',
	BUSINESSES_BY_AVERAGE_DESC_INDEX: 'businesses_byAverage_desc',
	BUSINESSES_BY_AVERAGE_ASC_INDEX: 'businesses_byAverage',
	BUSINESSES_BY_RATING_ASC_INDEX: 'businesses_byRating',
	SEO_DATA: 'seo_data_migration',
	RECAPTCHA_SITE_KEY: '6Lc0pY0fAAAAABF-ARe9wyleH8RYEgEB4FI7lZh6',
	RECAPTCHA_MACHINE_ARN:
		'arn:aws:states:eu-west-1:637668567762:stateMachine:sendPhoneNumberVerificationCodeRecaptchaProtectedLab',
	DIDOMI_PUBLIC_KEY: '13d3ec9d-8fde-4b8f-a007-4e60d6965caf',
	DIDOMI_NOTICE_ID: 'Ur3qMWGt',
	DIDOMI_NOTICE_ID_WHITE_LABEL: 'xx4h7KWT',
	SAVE_CARD_CONTROL_ACTIVATION_DATE: 1692008207,
	IOS_USER_APP_ID: 'E3KBT754EQ.com.planity.user.lab.mobile',
	ANDROID_USER_APP_ID: 'com.planitypublic.lab',
	ANDROID_SHA256_CERT_FINGERPRINTS: [
		'29:8A:0F:CC:D5:0D:97:60:E8:A6:9C:78:07:14:E6:9F:7D:89:71:23:B8:10:E0:30:E0:82:E7:BA:2B:6C:2C:87', // Certificate release
		'37:97:1B:34:DD:A4:CA:7E:D8:FD:DE:91:80:14:B8:2E:39:39:DB:E1:2B:54:6E:D0:22:54:EF:36:0A:B5:E9:A0' // Certificate debug
	],
	GOOGLE_CONVERSION_ENDPOINT:
		'https://www.google.com/maps/conversion/debug/collect',
	GOOGLE_PARTNERID: 20000120,
	USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED: true,
	USE_CURE_FOR_PAYMENTS_ENABLED: true,
	CDN_IMAGES_PLANITY: 'https://assets.cdn.global.labsharedsvc.planity.services'
};
