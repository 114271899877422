export default {
	FIREBASE_API_KEY: 'AIzaSyCX1DF342hoySVpnrhThQNo5EsMs7H52vo',
	FIREBASE_AUTH_DOMAIN: 'planity-staging.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://planity-staging.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_URL:
		'https://planity-staging-availabilities.firebaseio.com',
	FIREBASE_PUBLIC_SHARD_URL: 'https://planity-staging-public.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_1_URL:
		'https://planity-staging-availabilities-1.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_2_URL:
		'https://planity-staging-availabilities-2.europe-west1.firebasedatabase.app',
	FIREBASE_STORAGE_BUCKET: 'planity-staging.appspot.com',
	AWS_API_ENDPOINT:
		'https://2biltx0q4d.execute-api.eu-west-1.amazonaws.com/prod',
	ALGOLIA_APP_ID: 'AJZBI652UZ',
	ALGOLIA_API_KEY: '1e79c8f14df9f1a8f50a4cfb03c9eab0',
	ALGOLIA_PRODUCTION_APP_ID: 'DAY79MUBW3',
	ALGOLIA_PRODUCTION_API_KEY: '8ec84cdda274cec79b9ad155973bc864',
	ALGOLIA_PLACES_APP_ID: 'pl574WJIL1Q2',
	ALGOLIA_PLACES_API_KEY: '719fde67c8532799b0f99a4d2b454daa',
	GOOGLE_API_BROWSER_KEY:
		process.env.NODE_ENV === 'development'
			? 'AIzaSyBvhQQf1t6BHskINw9SyKdI8AE0pW4KTFc'
			: 'AIzaSyDg_KmgH0BMgedJ7wYRSITlHuemZ4sutp4',
	GOOGLE_API_SERVER_KEY: 'AIzaSyBADSy0MTRG83rTYztP2na1XFPKpAMBONU',
	HOST: 'planitytest.com',
	CLOUD_FUNCTIONS_DOMAIN:
		'https://us-central1-planity-staging.cloudfunctions.net',
	MAPBOX_API_KEY:
		'pk.eyJ1IjoicGxhbml0eSIsImEiOiI4QU5kRy1jIn0.suHIBfZHkiiPnuVFhQSRMg',
	USER_APP_LINK: 'https://planity.onelink.me/kxeJ/krrk0yft',
	PARANOID_AVAILABILITIES: ['saco'],
	DISPLAY_REVIEWS: true,
	STRIPE_API_KEY:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_FR:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_BE:
		'pk_test_51Lfk9VFCwhxbUJ7mSdwaFCrP38BgelUQPvOjK7zbpZGHIvdyVRkfpvreRVHeoees2df6titaLQEjUAtxQfscNPkc00clFCnAsf',
	STRIPE_API_KEY_DE:
		'pk_test_51L15sZE7ePEuIRmRVnUPGutdLBj8udBvUsP86xlxMvdklBgHOSP2E3b57cz9rAP1wtdYCh4vjFazd49CiqUNwxgU0003HcpCuk',
	ENABLE_FACEBOOK_LOGIN: false,
	ENABLE_GOOGLE_LOGIN: false,
	ENABLE_GITHUB_LOGIN: false,
	AVAILABLE_PROVIDERS: ['password'],
	HIDE_GIFT_VOUCHER_BUTTON: false,
	ENABLE_USER_PAYS_FEES: true,
	PLANITY_FEE: 1.58,
	PLANITY_FLAT: 18,
	STRIPE_FEE: 0,
	STRIPE_FLAT: 0,
	HIDE_ESHOP: true,
	ENABLE_CLICK_AND_COLLECT: 'true',
	ENABLE_CLICK_AND_SHOP: true,
	WOOTRIC_ACCOUNT_TOKEN: 'NPS-593dabed',
	WOOTRIC_NO_SURVEYED_COOKIES: false,
	WOOTRIC_SURVEY_IMMEDIATELY: false,
	ENABLE_NPS: false,
	ENABLE_ENRICHMENT: true,
	CATEGORIES_INDEX: 'categories',
	TAGS_INDEX: 'tags',
	PLACES_INDEX: 'places',
	BUSINESSES_INDEX: 'businesses',
	BUSINESSES_BY_AVERAGE_DESC_INDEX: 'businesses_byAverage_desc',
	BUSINESSES_BY_AVERAGE_ASC_INDEX: 'businesses_byAverage',
	BUSINESSES_BY_RATING_ASC_INDEX: 'businesses_byRating',
	SEO_DATA: 'seo_data_migration',
	RECAPTCHA_SITE_KEY: '6LclxTYgAAAAAH-7f-OFTUSveWq8FWuOYNdXD5b0',
	RECAPTCHA_MACHINE_ARN:
		'arn:aws:states:eu-west-1:637668567762:stateMachine:sendPhoneNumberVerificationCodeRecaptchaProtectedStaging',
	DIDOMI_PUBLIC_KEY: '13d3ec9d-8fde-4b8f-a007-4e60d6965caf',
	DIDOMI_NOTICE_ID: 'XPk8yeMb',
	DIDOMI_NOTICE_ID_WHITE_LABEL: 'rZzttq3f',
	SAVE_CARD_CONTROL_ACTIVATION_DATE: 1694609216,
	IOS_USER_APP_ID: 'E3KBT754EQ.com.planity.user.staging.mobile',
	ANDROID_USER_APP_ID: 'com.planitypublic.staging',
	ANDROID_SHA256_CERT_FINGERPRINTS: [
		'29:8A:0F:CC:D5:0D:97:60:E8:A6:9C:78:07:14:E6:9F:7D:89:71:23:B8:10:E0:30:E0:82:E7:BA:2B:6C:2C:87', // Certificate release
		'37:97:1B:34:DD:A4:CA:7E:D8:FD:DE:91:80:14:B8:2E:39:39:DB:E1:2B:54:6E:D0:22:54:EF:36:0A:B5:E9:A0' // Certificate debug
	],
	GOOGLE_CONVERSION_ENDPOINT:
		'https://www.google.com/maps/conversion/debug/collect',
	GOOGLE_PARTNERID: 20000120,
	USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED: true,
	USE_CURE_FOR_PAYMENTS_ENABLED: true,
	CDN_IMAGES_PLANITY: 'https://assets.cdn.global.labsharedsvc.planity.services'
};
